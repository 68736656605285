<template>
  <div class="swiper-slide">
    <div class="item">
      <div class="chart">
        <div class="title">
          <h6>{{ process_name }}</h6>
          <span>{{ `SUB ${sub}` }}</span>
        </div>
        <div class="chartData">
          <!-- <div class="chart_info">
            <span>SEQ1</span>
            <span>SEQ2</span>
            <span>SEQ3</span>
            <span>SEQ4</span>
          </div> -->
          <div :id="this.id"></div>
          <ProductionBarChart
            :key="localKey"
            :data="data"
            :options="options"
          ></ProductionBarChart>
        </div>
      </div>
      <div class="tbl_data">
        <div class="title">
          <span>세부공정</span>
          <button class="btn_tbl" @click="chkProcess">공정현황</button>
        </div>
        <div class="tbl">
          <table>
            <thead>
              <tr>
                <th>SEQ</th>
                <th>일자</th>
                <th>완료</th>
                <th>불량</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(seq, index) in sorted_production_seq"
                :key="seq.id"
                @click="running_plc ? '' : selectRow(index)"
                :class="selectedIndex == index && !showStatus ? 'active' : ''"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ seq.create_time | formatDateNoHours }}</td>
                <td class="text_right">
                  <b>{{ $makeComma(seq.pass_quantity) }}</b>
                  {{
                    findInfoFromId(
                      units,
                      findInfoFromId(products, product_id).stock_unit_id,
                    ).name
                  }}
                </td>
                <td class="text_right">
                  <b>{{ $makeComma(seq.fail_quantity) }}</b>
                  {{
                    findInfoFromId(
                      units,
                      findInfoFromId(products, product_id).stock_unit_id,
                    ).name
                  }}
                </td>
              </tr>
              <tr
                v-if="
                  $makeNumber(remain_quantity) > 0 &&
                    lot_type_id !=
                      lot_types.find(x => x.detail == 'done process').id
                "
                @click="running_plc ? '' : selectRow(-1)"
                :class="selectedIndex == -1 && !showStatus ? 'active' : ''"
              >
                <td>
                  {{ running_plc ? 'Sync' : 'new' }}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="process_data active">
        <table>
          <tbody v-if="showStatus">
            <tr>
              <th colspan="2" class="process_title">
                {{ `${process_name}공정 진행현황` }}
              </th>
            </tr>
            <tr>
              <th>진행</th>
              <td>
                {{
                  `${$makeComma(
                    $decimalSub(wait_quantity, remain_quantity),
                  )} / ${$makeComma(wait_quantity)}`
                }}
              </td>
            </tr>
            <tr>
              <th>통과</th>
              <td>
                {{
                  production_info != null
                    ? $makeComma(production_info.pass_quantity)
                    : 0
                }}
              </td>
            </tr>
            <tr>
              <th>불량</th>
              <td>
                {{
                  production_info != null
                    ? $makeComma(production_info.fail_quantity)
                    : 0
                }}
              </td>
            </tr>
          </tbody>
          <!-- <tbody
            v-if="
              $makeNumber(remain_quantity) <= 0 &&
              selectedIndex == -1 &&
              lot_type_id == 3
                ? selectedIndex == -1
                : false
            "
          >
            <tr>
              <th>일자</th>
              <td></td>
            </tr>
            <tr>
              <th>진행</th>
              <td></td>
            </tr>
            <tr>
              <th>통과</th>
              <td></td>
            </tr>
            <tr>
              <th>불량</th>
              <td></td>
            </tr>
          </tbody> -->

          <tbody v-else>
            <tr>
              <th>일자</th>
              <td>
                <input
                  type="text"
                  class="datepicker"
                  placeholder="YYYY-MM-DD"
                  :value="managementData.input_date"
                  readonly
                />
              </td>
            </tr>
            <tr>
              <th>진행</th>
              <td>
                <span :style="isOverflowQuantity ? 'color : red' : ''">{{
                  $makeComma(
                    $decimalAdd(
                      $makeNumber(managementData.pass_quantity),
                      $makeNumber(managementData.fail_quantity),
                    ),
                  )
                }}</span>
                / {{ $makeComma(managementData.quantity) }}
              </td>
            </tr>
            <tr>
              <th>통과</th>
              <td>
                <input
                  type="text"
                  inputmode="decimal"
                  :value="$makeComma(managementData.pass_quantity)"
                  @input="
                    $inputNumber(
                      $event,
                      managementData,
                      'pass_quantity',
                      $makeNumber(managementData.quantity),
                      0,
                    );
                    inputFailQuantity(
                      $makeNumber($event.target.value),
                      $makeNumber(managementData.quantity),
                    );
                  "
                  :readonly="
                    !isPermissionOn || selectedIndex != -1 || running_plc
                  "
                />
              </td>
            </tr>
            <tr>
              <th>불량</th>
              <td>
                <input
                  type="text"
                  inputmode="decimal"
                  :value="$makeComma(managementData.fail_quantity)"
                  @input="
                    $inputNumber(
                      $event,
                      managementData,
                      'fail_quantity',
                      $decimalSub(
                        $makeNumber(managementData.quantity),
                        $makeNumber(managementData.pass_quantity),
                      ),
                      0,
                    )
                  "
                  :readonly="
                    !isPermissionOn || selectedIndex != -1 || running_plc
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          @click="submit"
          class="btn_sub2"
          v-if="
            !showStatus &&
              !(remain_quantity <= 0 && selectedIndex == -1) &&
              !running_plc &&
              isPermissionOn
          "
          v-show="lot_type_id == 3 ? selectedIndex != -1 : true && chkInput"
        >
          {{
            selectedIndex == -1
              ? package_process_yn && is_last_process
                ? '결과등록(포장)'
                : '승인'
              : package_process_yn && is_last_process
              ? '승인취소'
              : '승인취소'
          }}
        </button>
        <button
          v-if="
            !showStatus &&
              isCounterSensorProcess &&
              !(remain_quantity <= 0) &&
              selectedIndex == -1 &&
              !(is_last_process && package_process_yn) &&
              isPermissionOn
          "
          @click="running_plc = !running_plc"
        >
          {{ running_plc ? '연동종료' : '설비연동' }}
        </button>
      </div>
      <seq-verification-card
        :localKey="rerendering"
        v-if="selectedIndex != -1 && !showStatus && isPermissionOn"
        :seq_id="sorted_production_seq[selectedIndex].id"
      ></seq-verification-card>
    </div>
    <package-process
      v-if="showPackageProcess"
      :packageInfo="packageInfo"
      :process_id="process_id"
      :lot_id="lot_id"
      @onClose="showPackageProcess = false"
      @onSubmit="
        $event => {
          showPackageProcess = false;
          $emit('oninsert', $event);
          selectRow(-1);
        }
      "
    ></package-process>
    <three-button-modal
      :modal_title="'수량(중량)카운터 초기화여부'"
      :modal_content="
        getCounterSensor.all.filter(x => x.value > 0).length > 0
          ? `연동하려는 설비 <br />'${getCounterSensor.all
              .filter(x => x.value > 0)
              .map(x => x.plc_sensor_name)
              .join(
                ',<br />',
              )}'<br />의 카운터 값이 '0' 이상입니다.<br/><br/> 값을 '0'으로 초기화하고<br/>진행하시겠습니까? `
          : ''
      "
      :show_modal="showThreeButtonModal"
      @onYes="
        resetAndProcessingCounter();
        showThreeButtonModal = false;
      "
      @onNo="
        processingCounter();
        showThreeButtonModal = false;
      "
      @onClose="showThreeButtonModal = false"
    ></three-button-modal>
    <div v-if="showPackageProcess" class="modal_layer"></div>
  </div>
</template>

<script>
// import tuiChart from 'tui-chart';
import { yyyymmdd } from '@/utils/func';
import { formatDateNoHours } from '@/utils/filters';
import { mapGetters } from 'vuex';
import FavoriteMixin from '@/mixins/favorite';
import ProductionBarChart from '@/layouts/components/charts/ProductionBarChart';
import SeqVerificationCard from '@/layouts/components/SeqVerificationCard';
import PackageProcess from '@/layouts/components/PackageProcess';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import ThreeButtonModal from '@/layouts/components/ThreeButtonModal';

export default {
  mixins: [FETCH_MIXIN, SPINNER_MIXIN, FavoriteMixin],
  components: {
    ProductionBarChart,
    SeqVerificationCard,
    PackageProcess,
    ThreeButtonModal,
  },
  data() {
    return {
      rerendering: 0,
      selectedIndex: -1,
      chart: null,
      managementData: {},
      data: null,
      options: null,
      localKey: 0,
      screenWidth: this.$screen.width,
      showPackageProcess: false,
      packageInfo: null,

      //plc flag
      running_plc: false,
      is_plc_running_ok: true,
      just_running: false,
      timer: 1,
      counter_interval: null,
      sensor_list: null,
      showStatus: false,
      showThreeButtonModal: false,
      resetTime: null,
    };
  },
  props: {
    process_id: {
      required: true,
    },
    process_name: {
      type: String,
    },
    is_last_process: {
      type: Boolean,
    },
    package_process_yn: {
      type: Boolean,
    },
    product_group_yn: {
      type: Boolean,
    },
    sub: {
      type: Number,
    },
    id: {
      type: String,
      required: true,
    },
    lot_quantity: {
      type: Number,
      required: true,
    },
    wait_quantity: {
      type: Number,
      required: true,
    },
    production_info: {
      required: true,
    },
    lot_id: {
      required: true,
    },
    product_id: {
      required: true,
    },
    bom_process_id: {
      required: true,
    },
    lot_type_id: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      lot_types: 'getLotType',
      lots: 'getLot',
      process: 'getProcess',
      machine: 'getMachines',
      products: 'getProduct',
      units: 'getUnitCodes',
      plc_sensors: 'getPlcSensor',
    }),
    isOverflowQuantity() {
      if (
        this.$makeNumber(this.managementData.quantity) <
        this.$decimalAdd(
          this.$makeNumber(this.managementData.pass_quantity),
          this.$makeNumber(this.managementData.fail_quantity),
        )
      ) {
        return true;
      } else return false;
    },
    isCounterSensorProcess() {
      const nowProcess = this.lodash.clonedeep(
        this.findInfoFromId(this.process, this.process_id),
      );
      if (nowProcess.machine_id != null) {
        const nowMachine = this.lodash.clonedeep(
          this.findInfoFromId(this.machine, nowProcess.machine_id),
        );
        if (
          nowMachine.counter_value != undefined &&
          nowMachine.counter_value.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getCounterSensor() {
      // sensor_list 현재 연동중인 plc로그리스트
      // 해당공정 카운터 센서 양품, 불량수량을 가져와서 리스트를 만들어줌
      if (this.sensor_list == null || !this.isCounterSensorProcess) {
        return { all: [], pass_counter: [], fail_counter: [] };
      } else {
        const passCountSensors = this.lodash
          .clonedeep(this.sensor_list)
          .filter(x =>
            this.getCounterSensorId.pass_id.includes(x.plc_sensor_id),
          );
        const failCountSensors = this.lodash
          .clonedeep(this.sensor_list)
          .filter(x =>
            this.getCounterSensorId.fail_id.includes(x.plc_sensor_id),
          );
        return {
          all: passCountSensors.concat(failCountSensors),
          pass_counter: passCountSensors,
          fail_counter: failCountSensors,
        };
      }
    },
    getCounterSensorId() {
      // 해당 공정의 양품수량과 불량수량 센서 id를 찾는다
      if (this.isCounterSensorProcess) {
        const nowProcess = this.lodash.clonedeep(
          this.findInfoFromId(this.process, this.process_id),
        );
        const nowMachine = this.lodash.clonedeep(
          this.findInfoFromId(this.machine, nowProcess.machine_id),
        );
        return {
          pass_id: this.lodash
            .clonedeep(nowMachine.counter_value)
            .filter(x => !x.poor_counter_yn)
            .map(x => x.counter_id),
          fail_id: this.lodash
            .clonedeep(nowMachine.counter_value)
            .filter(x => x.poor_counter_yn)
            .map(x => x.counter_id),
        };
      } else {
        return null;
      }
    },
    remain_quantity() {
      if (this.production_info != null) {
        const remainQuantity = this.$makeNumber(
          this.$decimalSub(
            this.$makeNumber(this.wait_quantity),
            this.$decimalAdd(
              this.$makeNumber(this.production_info.pass_quantity),
              this.$makeNumber(this.production_info.fail_quantity),
            ),
          ),
        );

        return remainQuantity;
      } else {
        return this.$makeNumber(this.wait_quantity);
      }
    },
    sorted_production_seq() {
      if (this.production_info != null) {
        return this.lodash.clonedeep(
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.production_info.seq_list.sort((a, b) => a.id - b.id),
        );
      } else {
        return [];
      }
    },
    chkInput() {
      // return this.$makeNumber(this.managementData.pass_quantity);
      let pass = this.$makeNumber(this.managementData.pass_quantity);
      let fail = this.$makeNumber(this.managementData.fail_quantity);

      if (pass == 0 && fail == 0) {
        return false;
      } else return true;
    },
  },

  mounted() {
    // console.log('!!!this.wait_quantity', this.wait_quantity);
    // console.log('!!!sub', this.sub);
    // console.log('!!!this.is_last_process', this.is_last_process);
    // console.log('!!!this.package_process_yn', this.package_process_yn);
    this.drawChart();
  },
  methods: {
    async resetAndProcessingCounter() {
      this.showSpinner();
      await this.$store
        .dispatch('RESET_PLC_COUNTER_VALUE', {
          address_list: this.getCounterSensor.all.map(
            x => this.plc_sensors.find(y => y.id == x.plc_sensor_id).address,
          ),
        })
        .then(response => {
          this.resetTime = new Date();
          console.log(response);
          this.just_running = true;
          this.is_plc_running_ok = true;
          this.running_plc = true;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('오류', '설비 값 초기화 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    processingCounter() {
      this.just_running = true;
      this.is_plc_running_ok = true;
      this.running_plc = true;
    },
    chkProcess() {
      this.showStatus = true;
    },
    async INSERT_COUNTER_LOG() {
      console.log('this.getCounterSensor', this.getCounterSensor.all);
      const insertCounterList = this.lodash
        .clonedeep(this.getCounterSensor.all)
        .map(x => {
          return {
            lot_id: this.lot_id,
            product_id: this.product_id,
            value: x.value,
            plc_sensor_detail_type_id: x.plc_sensor_detail_type_id,
            plc_sensor_id: x.plc_sensor_id,
          };
        });

      this.INSERT_WITH_PAYLOAD(
        'INSERT_COUNTER_LOG',
        { data_list: insertCounterList },
        '계수기 점유',
      );
    },
    async UPDATE_COUNTER_LOG() {
      await this.$store
        .dispatch('FETCH_COUNTER_LOG_NOW')
        .then(data_list => {
          if (data_list.length < 1) {
            // this.openOneButtonModal('오류', '연동 종료 중 오류발생');
          } else {
            let temp = this.lodash.clonedeep(data_list);
            temp = temp.map(x => {
              const nowVal = this.getCounterSensor.all.find(
                y => y != undefined && y.plc_sensor_id == x.plc_sensor_id,
              );
              if (nowVal != undefined) {
                return {
                  id: x.id,
                  value: nowVal.value,
                };
              } else {
                return {
                  id: x.id,
                  value: 0,
                };
              }
            });
            this.UPDATE_WITH_PAYLOAD('UPDATE_COUNTER_LOG', { data_list: temp });
          }
        })
        .catch(error => {
          console.log('error', error);
          this.openOneButtonModal('오류', '연동 종료 중 오류발생');
        });
    },
    async FETCH_SENSOR_LOG_LIST_NOW_CHECK() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(sensors => {
          this.sensor_list = sensors;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async FETCH_SENSOR_LOG_LIST_NOW() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(async sensors => {
          this.sensor_list = sensors;
          await this.changeRowValue(sensors);
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeRowValue(sensors) {
      if (sensors != undefined) {
        this.sensor_list = sensors;
      }

      if (this.getCounterSensor.all.length > 0) {
        if (this.getCounterSensor.pass_counter.length > 0) {
          if (
            this.resetTime == null ||
            new Date(this.getCounterSensor.pass_counter[0].create_time) >
              this.resetTime
          ) {
            const sumPassQuantity = this.lodash
              .clonedeep(this.getCounterSensor.pass_counter)
              .map(x => x.value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            this.managementData.pass_quantity = sumPassQuantity;
            this.resetTime = null;
          } else {
            this.managementData.pass_quantity = 0;
          }
        }
        if (this.getCounterSensor.fail_counter.length > 0) {
          if (
            this.resetTime == null ||
            new Date(this.getCounterSensor.fail_counter[0].create_time) >
              this.resetTime
          ) {
            const sumFailQuantity = this.lodash
              .clonedeep(this.getCounterSensor.fail_counter)
              .map(x => x.value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            this.managementData.fail_quantity = sumFailQuantity;
            this.resetTime = null;
          } else {
            this.managementData.fail_quantity = 0;
          }
        } else {
          this.managementData.fail_quantity = 0;
        }
      }
    },
    changeRowValueZero() {
      if (this.getCounterSensor.all.length > 0) {
        if (this.getCounterSensor.pass_counter.length > 0) {
          this.managementData.pass_quantity = 0;
        }
        if (this.getCounterSensor.fail_counter.length > 0) {
          this.managementData.fail_quantity = 0;
        } else {
          this.managementData.fail_quantity = 0;
        }
      }
    },
    inputFailQuantity(passQ, totalQ) {
      // this.managementData.pass_quantity = passQ;
      this.managementData.fail_quantity = totalQ - passQ;
    },
    submit() {
      if (this.isOverflowQuantity) {
        this.openOneButtonModal(
          '승인불가',
          '통과수량과 불량수량의 합은 진행수량을 넘을 수 없습니다',
        );
        return;
      }
      let payload = this.lodash.clonedeep(this.production_info);
      if (payload == null) {
        payload = {
          id: -1,
          lot_id: this.lot_id,
          bom_process_id: this.bom_process_id,
          lot_process_type_id: 1,
          pass_quantity: this.$makeNumber(this.managementData.pass_quantity),
          fail_quantity: this.$makeNumber(this.managementData.fail_quantity),
          input_date: this.managementData.input_date,
        };
      } else {
        payload.pass_quantity = this.$decimalAdd(
          this.$makeNumber(payload.pass_quantity),
          this.$makeNumber(this.managementData.pass_quantity),
        );
        payload.fail_quantity = this.$decimalAdd(
          this.$makeNumber(payload.fail_quantity),
          this.$makeNumber(this.managementData.fail_quantity),
        );
        payload.input_date = this.managementData.input_date;
      }
      payload.seq = this.lodash.clonedeep(this.managementData);
      if (this.selectedIndex == -1) {
        if (this.package_process_yn && this.is_last_process) {
          this.packageInfo = this.lodash.clonedeep(payload);
          this.packageInfo.product_id = this.lots.find(
            x => x.id == this.lot_id,
          ).product_id; // product_id undifined
          this.showPackageProcess = true;
        } else {
          this.$emit('oninsert', payload);
          this.selectRow(-1);
        }
      } else {
        if (this.package_process_yn && this.is_last_process) {
          // this.showPackageProcess = true;
          this.$emit('ondeletePackage', payload);
          this.selectRow(-1);
        } else {
          this.$emit('ondelete', payload);
          this.selectRow(-1);
        }
      }
    },

    selectRow(index) {
      this.showStatus = false;
      this.selectedIndex = index;
      if (index != -1) {
        const seq = this.production_info.seq_list[this.selectedIndex];
        this.managementData = {
          id: seq.id,
          input_date: formatDateNoHours(seq.create_time),
          quantity: this.$makeNumber(seq.quantity),
          pass_quantity: this.$makeNumber(seq.pass_quantity),
          fail_quantity: this.$makeNumber(seq.fail_quantity),
        };
      } else {
        var date = new Date();
        this.managementData = {
          id: -1,
          input_date: yyyymmdd(date),
          quantity: this.$makeNumber(this.remain_quantity),
          pass_quantity: this.$makeNumber(this.remain_quantity),
          fail_quantity: 0,
        };
      }
      this.rerendering += 1;
    },
    drawChart() {
      // console.log('process_id', this.process_id);
      // console.log('process_name', this.process_name);
      // console.log('package_process_yn', this.package_process_yn);
      // console.log('product_group_yn', this.product_group_yn);
      // console.log('sub', this.sub);
      // console.log('id', this.id);
      // console.log('lot_quantity', this.lot_quantity);
      // console.log('wait_quantity', this.wait_quantity);
      // console.log('production_info', this.production_info);
      // console.log('lot_id', this.lot_id);
      // console.log('bom_process_id', this.bom_process_id);
      // console.log('lot_type_id', this.lot_type_id);

      let pass_quantity = 0;
      let fail_quantity = 0;
      if (this.production_info != null) {
        pass_quantity = this.$makeNumber(this.production_info.pass_quantity);
        fail_quantity = this.$makeNumber(this.production_info.fail_quantity);
      }
      if (pass_quantity == undefined) pass_quantity = 0;
      if (fail_quantity == undefined) fail_quantity = 0;
      let list = [];
      let seq_list = null;

      if (this.production_info != null) {
        seq_list = this.lodash.clonedeep(
          this.production_info.seq_list.sort((a, b) => a.id - b.id),
        );
        let pass_ratio = 0;
        let fail_ratio = 0;
        if (
          seq_list.filter(x => this.$makeNumber(x.pass_quantity) > 0).length > 0
        ) {
          pass_ratio = (
            0.8 /
            seq_list.filter(x => this.$makeNumber(x.pass_quantity) > 0).length
          ).toFixed(12);
        }
        if (
          seq_list.filter(x => this.$makeNumber(x.fail_quantity) > 0).length > 0
        ) {
          fail_ratio = (
            0.8 /
            seq_list.filter(x => this.$makeNumber(x.fail_quantity) > 0).length
          ).toFixed(12);
        }

        let sumPass = 0;
        let sumFail = 0;

        seq_list.forEach(el => {
          el.pass_quantity = this.$makeNumber(el.pass_quantity);

          if (el.pass_quantity > 0) {
            list.push({
              // label: `SEQ${index + 1} 통과`,
              backgroundColor: `rgbA(0,74,137,${1 - sumPass})`,
              data: [el.pass_quantity, 0, 0],
              xAxisID: 'bar-x-axis1',
              tooltip: false,
            });
            sumPass = this.$decimalAdd(Number(sumPass), Number(pass_ratio));
            // label_pass_quantity =
            //   Number(label_pass_quantity) + Number(el.pass_quantity);
          }
        });
        seq_list.forEach(el => {
          if (el.fail_quantity > 0) {
            list.push({
              // label: `SEQ${index + 1} 불량`,
              backgroundColor: `rgbA(249,135,135,${1 - sumFail})`,
              data: [0, el.fail_quantity, 0],
              xAxisID: 'bar-x-axis1',
              tooltip: false,
            });
            sumFail = this.$decimalAdd(Number(sumFail), Number(fail_ratio));
          }
        });
      }
      list.push({
        // label: '미투입량',
        backgroundColor: '#dddddd',
        data: [
          0,
          0,
          this.$decimalSub(
            this.wait_quantity,
            this.$decimalAdd(pass_quantity, fail_quantity),
          ),
        ],
        xAxisID: 'bar-x-axis1',
        categoryPercentage: 0.8,
        tooltip: false,
      });

      let data = {
        labels: ['통과', '불량', '미투입량'],
        datasets: list,
      };

      let fontSize = 12;
      if (this.screenWidth < 1280) {
        fontSize = 10;
      }

      let maxLen = 0;
      if (this.lot_quantity >= 1) {
        let decimal = 0;
        decimal = String(Math.round(this.lot_quantity)).length - 1;
        let cnt = '1';
        for (let i = 0; i < decimal; i++) {
          cnt += '0';
        }
        this.$makeNumber(cnt);
        maxLen = Math.ceil(this.lot_quantity / cnt) * cnt;
      } else {
        maxLen = this.lot_quantity;
      }

      let options = {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              id: 'bar-x-axis1',
              ticks: {
                fontSize,
              },
            },
          ],
          dataset: {
            barPercentage: 0.9,
          },
          yAxes: [
            {
              stacked: true,
              ticks: {
                // beginAtZero: true,
                max: maxLen,
                fontSize,
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
          },
        },
        showTooltips: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            label: tooltipItems => {
              if (tooltipItems.datasetIndex === 0) {
                var multistringText = [];
                switch (tooltipItems.index) {
                  case 0:
                    if (seq_list != null) {
                      seq_list.forEach((el, index) => {
                        el.pass_quantity = this.$makeNumber(el.pass_quantity);

                        if (el.pass_quantity > 0) {
                          multistringText.push(
                            `[SEQ${index + 1} 통과] : ${this.$makeComma(
                              el.pass_quantity,
                            )}`,
                          );
                        }
                      });
                    }
                    break;
                  case 1:
                    if (seq_list != null) {
                      seq_list.forEach((el, index) => {
                        el.fail_quantity = this.$makeNumber(el.fail_quantity);

                        if (el.fail_quantity > 0) {
                          multistringText.push(
                            `[SEQ${index + 1} 불량] : ${this.$makeComma(
                              el.fail_quantity,
                            )}`,
                          );
                        }
                      });
                    }
                    break;
                  case 2:
                    multistringText.push(
                      `${this.$makeComma(
                        this.$decimalSub(
                          this.wait_quantity,
                          this.$decimalSub(pass_quantity, fail_quantity),
                        ),
                      )}`,
                    );
                    break;
                }
                return multistringText;
              }
            },
          },
        },
      };
      this.data = data;
      this.options = options;
      this.localKey += 1000;
    },
  },
  watch: {
    lot_quantity() {
      // this.chart.destroy();
      this.drawChart();
    },
    async running_plc(newValue, oldValue) {
      this.showSpinner();
      await this.FETCH_SENSOR_LOG_LIST_NOW_CHECK();
      if (oldValue == false && newValue == true) {
        const filteredSensors = this.getCounterSensor.all.filter(
          x => x.value > 0,
        );
        console.log('filteredSensors', filteredSensors);
        if (filteredSensors.length == 0 || this.just_running) {
          this.is_plc_running_ok = true;

          this.counter_interval = setInterval(() => {
            this.timer -= 1;
          }, 1000);
          await this.INSERT_COUNTER_LOG();
          if (this.just_running) {
            this.just_running = false;
            await this.changeRowValue();
          } else {
            await this.changeRowValueZero();
          }
        } else {
          // 초기화 할지 물어봄
          this.showThreeButtonModal = true;
          this.just_running = false;
          this.is_plc_running_ok = false;
          this.running_plc = false;
        }
      } else {
        if (this.is_plc_running_ok) {
          clearInterval(this.counter_interval);
          await this.UPDATE_COUNTER_LOG();
        }
      }
      this.hideSpinner();
    },
    async timer(newValue) {
      if (newValue == 0) {
        await this.FETCH_SENSOR_LOG_LIST_NOW();
        this.timer = 10;
      }
    },
  },
  async destroyed() {
    clearInterval(this.counter_interval);
    await this.UPDATE_COUNTER_LOG();
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', '센서');
    }
    if (this.lots.length < 1) {
      await this.FETCH('FETCH_LOT_ALL', '작업지시');
    }
    this.selectedIndex = -1;
    var date = new Date();
    this.managementData = {
      input_date: yyyymmdd(date),
      quantity: this.remain_quantity,
      pass_quantity: this.remain_quantity,
      fail_quantity: 0,
    };
    if (this.remain_quantity <= 0) {
      this.showStatus = true;
    }
  },
};
</script>

<style lang="scss" scoped></style>
