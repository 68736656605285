var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"chart"},[_c('div',{staticClass:"title"},[_c('h6',[_vm._v(_vm._s(_vm.process_name))]),_c('span',[_vm._v(_vm._s(("SUB " + _vm.sub)))])]),_c('div',{staticClass:"chartData"},[_c('div',{attrs:{"id":this.id}}),_c('ProductionBarChart',{key:_vm.localKey,attrs:{"data":_vm.data,"options":_vm.options}})],1)]),_c('div',{staticClass:"tbl_data"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("세부공정")]),_c('button',{staticClass:"btn_tbl",on:{"click":_vm.chkProcess}},[_vm._v("공정현황")])]),_c('div',{staticClass:"tbl"},[_c('table',[_vm._m(0),_c('tbody',[_vm._l((_vm.sorted_production_seq),function(seq,index){return _c('tr',{key:seq.id,class:_vm.selectedIndex == index && !_vm.showStatus ? 'active' : '',on:{"click":function($event){_vm.running_plc ? '' : _vm.selectRow(index)}}},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDateNoHours")(seq.create_time)))]),_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(_vm._s(_vm.$makeComma(seq.pass_quantity)))]),_vm._v(" "+_vm._s(_vm.findInfoFromId( _vm.units, _vm.findInfoFromId(_vm.products, _vm.product_id).stock_unit_id ).name)+" ")]),_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(_vm._s(_vm.$makeComma(seq.fail_quantity)))]),_vm._v(" "+_vm._s(_vm.findInfoFromId( _vm.units, _vm.findInfoFromId(_vm.products, _vm.product_id).stock_unit_id ).name)+" ")])])}),(
                _vm.$makeNumber(_vm.remain_quantity) > 0 &&
                  _vm.lot_type_id !=
                    _vm.lot_types.find(function (x) { return x.detail == 'done process'; }).id
              )?_c('tr',{class:_vm.selectedIndex == -1 && !_vm.showStatus ? 'active' : '',on:{"click":function($event){_vm.running_plc ? '' : _vm.selectRow(-1)}}},[_c('td',[_vm._v(" "+_vm._s(_vm.running_plc ? 'Sync' : 'new')+" ")]),_c('td'),_c('td'),_c('td')]):_vm._e()],2)])])]),_c('div',{staticClass:"process_data active"},[_c('table',[(_vm.showStatus)?_c('tbody',[_c('tr',[_c('th',{staticClass:"process_title",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s((_vm.process_name + "공정 진행현황"))+" ")])]),_c('tr',[_c('th',[_vm._v("진행")]),_c('td',[_vm._v(" "+_vm._s(((_vm.$makeComma( _vm.$decimalSub(_vm.wait_quantity, _vm.remain_quantity) )) + " / " + (_vm.$makeComma(_vm.wait_quantity))))+" ")])]),_c('tr',[_c('th',[_vm._v("통과")]),_c('td',[_vm._v(" "+_vm._s(_vm.production_info != null ? _vm.$makeComma(_vm.production_info.pass_quantity) : 0)+" ")])]),_c('tr',[_c('th',[_vm._v("불량")]),_c('td',[_vm._v(" "+_vm._s(_vm.production_info != null ? _vm.$makeComma(_vm.production_info.fail_quantity) : 0)+" ")])])]):_c('tbody',[_c('tr',[_c('th',[_vm._v("일자")]),_c('td',[_c('input',{staticClass:"datepicker",attrs:{"type":"text","placeholder":"YYYY-MM-DD","readonly":""},domProps:{"value":_vm.managementData.input_date}})])]),_c('tr',[_c('th',[_vm._v("진행")]),_c('td',[_c('span',{style:(_vm.isOverflowQuantity ? 'color : red' : '')},[_vm._v(_vm._s(_vm.$makeComma( _vm.$decimalAdd( _vm.$makeNumber(_vm.managementData.pass_quantity), _vm.$makeNumber(_vm.managementData.fail_quantity) ) )))]),_vm._v(" / "+_vm._s(_vm.$makeComma(_vm.managementData.quantity))+" ")])]),_c('tr',[_c('th',[_vm._v("통과")]),_c('td',[_c('input',{attrs:{"type":"text","inputmode":"decimal","readonly":!_vm.isPermissionOn || _vm.selectedIndex != -1 || _vm.running_plc},domProps:{"value":_vm.$makeComma(_vm.managementData.pass_quantity)},on:{"input":function($event){_vm.$inputNumber(
                    $event,
                    _vm.managementData,
                    'pass_quantity',
                    _vm.$makeNumber(_vm.managementData.quantity),
                    0
                  );
                  _vm.inputFailQuantity(
                    _vm.$makeNumber($event.target.value),
                    _vm.$makeNumber(_vm.managementData.quantity)
                  );}}})])]),_c('tr',[_c('th',[_vm._v("불량")]),_c('td',[_c('input',{attrs:{"type":"text","inputmode":"decimal","readonly":!_vm.isPermissionOn || _vm.selectedIndex != -1 || _vm.running_plc},domProps:{"value":_vm.$makeComma(_vm.managementData.fail_quantity)},on:{"input":function($event){_vm.$inputNumber(
                    $event,
                    _vm.managementData,
                    'fail_quantity',
                    _vm.$decimalSub(
                      _vm.$makeNumber(_vm.managementData.quantity),
                      _vm.$makeNumber(_vm.managementData.pass_quantity)
                    ),
                    0
                  )}}})])])])]),(
          !_vm.showStatus &&
            !(_vm.remain_quantity <= 0 && _vm.selectedIndex == -1) &&
            !_vm.running_plc &&
            _vm.isPermissionOn
        )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lot_type_id == 3 ? _vm.selectedIndex != -1 : true && _vm.chkInput),expression:"lot_type_id == 3 ? selectedIndex != -1 : true && chkInput"}],staticClass:"btn_sub2",on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.selectedIndex == -1 ? _vm.package_process_yn && _vm.is_last_process ? '결과등록(포장)' : '승인' : _vm.package_process_yn && _vm.is_last_process ? '승인취소' : '승인취소')+" ")]):_vm._e(),(
          !_vm.showStatus &&
            _vm.isCounterSensorProcess &&
            !(_vm.remain_quantity <= 0) &&
            _vm.selectedIndex == -1 &&
            !(_vm.is_last_process && _vm.package_process_yn) &&
            _vm.isPermissionOn
        )?_c('button',{on:{"click":function($event){_vm.running_plc = !_vm.running_plc}}},[_vm._v(" "+_vm._s(_vm.running_plc ? '연동종료' : '설비연동')+" ")]):_vm._e()]),(_vm.selectedIndex != -1 && !_vm.showStatus && _vm.isPermissionOn)?_c('seq-verification-card',{attrs:{"localKey":_vm.rerendering,"seq_id":_vm.sorted_production_seq[_vm.selectedIndex].id}}):_vm._e()],1),(_vm.showPackageProcess)?_c('package-process',{attrs:{"packageInfo":_vm.packageInfo,"process_id":_vm.process_id,"lot_id":_vm.lot_id},on:{"onClose":function($event){_vm.showPackageProcess = false},"onSubmit":function ($event) {
        _vm.showPackageProcess = false;
        _vm.$emit('oninsert', $event);
        _vm.selectRow(-1);
      }}}):_vm._e(),_c('three-button-modal',{attrs:{"modal_title":'수량(중량)카운터 초기화여부',"modal_content":_vm.getCounterSensor.all.filter(function (x) { return x.value > 0; }).length > 0
        ? ("연동하려는 설비 <br />'" + (_vm.getCounterSensor.all
            .filter(function (x) { return x.value > 0; })
            .map(function (x) { return x.plc_sensor_name; })
            .join(
              ',<br />'
            )) + "'<br />의 카운터 값이 '0' 이상입니다.<br/><br/> 값을 '0'으로 초기화하고<br/>진행하시겠습니까? ")
        : '',"show_modal":_vm.showThreeButtonModal},on:{"onYes":function($event){_vm.resetAndProcessingCounter();
      _vm.showThreeButtonModal = false;},"onNo":function($event){_vm.processingCounter();
      _vm.showThreeButtonModal = false;},"onClose":function($event){_vm.showThreeButtonModal = false}}}),(_vm.showPackageProcess)?_c('div',{staticClass:"modal_layer"}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("SEQ")]),_c('th',[_vm._v("일자")]),_c('th',[_vm._v("완료")]),_c('th',[_vm._v("불량")])])])}]

export { render, staticRenderFns }